<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="background-color: rgb(97, 116, 152); height: 32px; border-radius: 4px;">
                            <h4 style="color: rgb(223, 227, 238); margin-left: 5px; font-weight: bold;">
                                Vehicle Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group label="Company Name:" label-for="company_name">
                                    <b-form-input id="company_name" placeholder="Enter company name" v-model="company_name"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Name:" label-for="brand_id">
                                    <b-form-select id="brand_id" placeholder="Select brand id" v-model="brand_id" :options="brands" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Model:" label-for="brand_model_id">
                                    <b-form-select id="brand_model_id" placeholder="Select brand model" v-model="brand_model_id" :options="brandModels" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group label="Vehicle Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter vehicle color" v-model="color"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group label="License Plate:" label-for="license_plate">
                                    <b-form-input id="license_plate" placeholder="Enter license plate" v-model="license_plate"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number: "
                                    label-for="engine_number">
                                    <b-form-input id="engine_number"
                                        placeholder="Enter engine number" v-model="engine_number" required
                                        ></b-form-input>
                                </b-form-group>
                            </div>
                        
                            <div class="col-md-4 col-12">
                                <b-form-group label="Approve Status:" label-for="types">
                                    <b-form-input id="types" placeholder="Enter types" v-model="types"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group label="Registration Number:" label-for="registration_number">
                                    <b-form-input id="registration_number" placeholder="Enter registration number" v-model="registration_number"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group label="Vehicle Year:" label-for="year">
                                    <b-form-input id="year" placeholder="Enter vehicle year" v-model="year"></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Identification Number: "
                                    label-for="vin">
                                    <b-form-input id="vin" 
                                        placeholder="Enter vehicle identification number:" v-model="vin"
                                        required></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Unique Identification Code:" label-for="uic">
                                    <b-form-input id="uic" placeholder="Enter unique identification code" v-model="uic" required></b-form-input>
                                </b-form-group>
                                </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Status:"
                                    label-for="status">
                                    <b-form-input id="status"
                                        placeholder="Enter status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                  
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Vehicle Images:" label-for="images">
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    @change="onProfilePictureChange"
                                />
      
                         <div v-if="image && image.length" style="margin-left: 3px; margin-bottom: 15px">
                        <img
                            v-for="(image, index) in image"
                            :key="index"
                            :src="'https://wossdb.wossautoid.com/' + image"
                            alt="Vehicle Image"
                            width="100"
                            height="100"
                            style="margin-right: 10px"
                        />
                        </div>
                            </b-form-group>
                        </div>

                        <!-- Doucment code  here  -->
    
                            <div class="col-md-4 col-12">
                        <b-form-group id="input-group-2" label="Documents:" label-for="documents">
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                multiple
                                @change="onDocumentChange"
                            />
                            <div v-if="documentPreviews.length" style="margin-left: 3px; margin-bottom: 15px">
                                <div v-for="(preview, index) in documentPreviews" :key="index">
                                    <span 
                                        v-if="preview.endsWith('.pdf')" 
                                        style="margin-right: 10px; margin-bottom: 10px; margin-top: 10px; cursor: pointer;"
                                        @click="openDocument(preview)"
                                    >
                                            <i class="fas fa-file-pdf" style="margin-right: 5px;"></i>Pdf 
                                        </span>
                                        <img
                                            v-else
                                            :src="preview"
                                            alt="Document"
                                            width="100"
                                            height="100"
                                            style="margin-right: 10px"
                                        />
                                    </div>
                                    </div>
                                </b-form-group>
                            </div>

                        </div>

                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
                        <code class="hljs html">{{ codeText }}</code>
                    </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BToast,
    BFormSelect,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
// import { BToast } from "bootstrap-vue";

export default {
    data() {
        return {
            show: true,
            isLoading: false,

            company_name: "",
            model: "",
            color: "",
            license_plate: "",
            brand_model_id: '',
            brandModels:[],
            brand_id: '',
            brands: [], 
            types: "",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            engine_number:"",
            status: "safe",
            image: [], 
            imageFiles: [], 
            imagePreviews: [],   
            documents: [], 
            documentFiles: [],
            documentPreviews: [],
            editedUser: {},
            successMessage: "", 
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BSpinner,
        BToast
    },
    mounted() {
                axios.get('brand')
                    .then(response => {
                        this.brands = response.data.data.map(brand => ({
                            value: brand.id,  
                            text: brand.name  
                        }));
                        console.log(this.brands);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    axios.get('brandModel')
                        .then(response => {
    
                            this.brandModels = response.data.data.map(model => ({
                                value: model.id,  
                                text: model.title 
                            }));
                            console.log(this.brandModels);
                        })
                        .catch(error => {
                            console.log(error);
                        });
            },

    created() {
        const userId = this.$route.params.id;
        axios
            .get(`vehicleShow/${userId}`)
            .then((response) => {
                const editedUser = response.data.data;
                this.editedUser = editedUser;
                this.company_name = editedUser.company_name;
                this.brand_model_id = editedUser.brand_model_id;
                this.color = editedUser.color;
                this.license_plate = editedUser.license_plate;
                this.brand_id = editedUser.brand_id;
                this.types = editedUser.types;
                this.engine_number = editedUser.engine_number;
                this.image = editedUser.image;
                this.registration_number = editedUser.registration_number;
                this.vin = editedUser.vin;
                this.uic = editedUser.uic;
                this.year = editedUser.year;
                this.status = editedUser.status;

            this.imagePreviews = editedUser.image.map(img => `https://wossdb.wossautoid.com/${img}`);
                this.imageFiles = new Array(editedUser.image.length).fill(null);
            this.documentPreviews = this.editedUser.documents.map(
                doc => `https://wossdb.wossautoid.com/${doc.documents}`
            );
            })
            .catch((error) => {
                console.error("Error fetching vehicle data:", error);
            });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
        addUser() {
            
            this.isLoading = true;
            const formData = new FormData();
            formData.append("company_name", this.company_name);
            formData.append("brand_model_id", this.brand_model_id);
            formData.append("brand_id", this.brand_id);
            formData.append("color", this.color);
            formData.append("license_plate", this.license_plate);
            formData.append("types", this.types);
            formData.append("engine_number", this.engine_number);
            formData.append("registration_number", this.registration_number);
            formData.append("vin", this.vin);
            formData.append("uic", this.uic);
            formData.append("year", this.year);
            formData.append("status", this.status);
             // Append image files
            this.imageFiles.forEach((file, index) => {
                if (file) {
                    formData.append(`image[${index}]`, file);
                }
            });
          // Append document files
        this.documentFiles.forEach((file, index) => {
            formData.append(`document[${index}]`, file);
        });
            axios
                .post(`vehicleUpdate/${this.editedUser.id}`, formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Vehicle Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary",
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListVehicle' });
                })
                .catch((error) => {
                    console.log("Error updating vehicle:", error.response.data);
                    this.isLoading = false;
                });
        },
       
        onProfilePictureChange(event) {
            const files = Array.from(event.target.files);
            files.forEach(file => {
                this.imageFiles.push(file);
                this.imagePreviews.push(URL.createObjectURL(file));
            });
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },
        onDocumentChange(event) {
        const files = event.target.files;
        this.documentFiles = Array.from(files);  // Store the files in an array for uploading
        this.documentPreviews = this.documentFiles.map((file) => {
            return file.type === 'application/pdf' ? URL.createObjectURL(file) : URL.createObjectURL(file);
        });
    },
    openDocument(preview) {
        window.open(preview, '_blank');
    },


    }
};
</script>
